
import { getHttpEndpoint } from "@orbs-network/ton-access";

import { TonClient } from "@ton/ton";


import { useAsyncInitialize } from "./useAsyncInitialize.ts";
import {useTonConnect} from "./useTonconnect.ts";
import TonWeb from 'tonweb';
export function useTonClient() {
    const {network} = useTonConnect()

    // return {
    //     client: useAsyncInitialize(async ()=>{
    //         if(!network) return;

    //         const endpoint = await getHttpEndpoint({                    
    //              network: network === "Mainnet" ? "mainnet" : "testnet"
    //         })
    //         return new TonClient({
    //             endpoint: endpoint,
                
    //         })
    //     }, [network])
    // }

    return {
        client: useAsyncInitialize(async () => {
            if (!network) return;

            const endpoint = network === "Mainnet" 
                ? "https://toncenter.com/api/v2/jsonRPC"  // или используйте свой API ключ: https://toncenter.com/api/v2/jsonRPC?api_key=YOUR_API_KEY
                : "https://testnet.toncenter.com/api/v2/jsonRPC";

            return new TonClient({
                endpoint: endpoint,
                apiKey: "97dfa9aa56ceb2a0045be1585e26c085d97adfbc7f1a3e1d8b0a679d47d50553" // замените на ваш API ключ от TonCenter
            });
        }, [network])
    };
}
