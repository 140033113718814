import { useState, useEffect, useCallback, useContext } from 'react';
import TonWeb, { StackElement } from 'tonweb';
import { contractAddress, Contract , OpenedContract, toNano, Address, address, beginCell } from '@ton/core';
import { Sender, TonClient, HttpApi } from '@ton/ton';
import { keyPairFromSeed, sign } from '@ton/crypto';


import { Mint, NftContractsecond, UpdateMeta } from '../wrappers/NftContractsecond'; 
import { NftVaucher, NftItemVaucher } from '../wrappers/NftVaucher';
import { NftItem, SetGameAttributes, GameAttributes, UpdateContent } from '../wrappers/NftItem';
import { getHttpEndpoint } from "@orbs-network/ton-access"; 
import { useTonConnect } from './useTonconnect'; 
import { useTonClient } from './useTonClient';
import { toast } from 'react-toastify';
import { UserContext } from '../UserContext.js';
import { Mint as MintVaucher } from '../wrappers/NftVaucher';
import { Activate } from '../build/NftVaucher/tact_NftItemVaucher.js';


export function useContractData() {
    const [data, setData] = useState({
        next_item: 0,
        nextItemIndex: 0,
    });
    
    const fetchData = useCallback(async () => {
        const tonweb = new TonWeb(new TonWeb.HttpProvider('https://sandbox.tonhubapi.com/jsonRPC'));
        
        const contractAddress = 'EQAz5VwT0gvPCX_WmqkXwsw2FG-QLDfFYDZIwbckZpx_U6HZ';

        try {
            const nftCollection = new TonWeb.token.nft.NftCollection(tonweb.provider, { address: contractAddress });
            const result = await nftCollection.getCollectionData();
            
            setData({
                next_item: result.nextItemIndex,
                nextItemIndex: result.nextItemIndex
            });
        } catch (error) {
            console.error('Ошибка вызова контракта:', error.message);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        next_item: data.next_item,
        nextItemIndex: data.nextItemIndex,
        refetch: fetchData
    };
}

export function useContractMint(){
    
    const { wallet , sender, boc, tonAddress } = useTonConnect();
    const {client} = useTonClient();
    const [bocValue, setBocValue] = useState<string | null>(null);
    const [listerentransaction, setListerentransaction] = useState(false);
    const [distans1, setDistans1] = useState<GameAttributes | null>(null);
    const [AdressNftItemGlobal, setAdressNftItemGlobal] = useState<string | null>(null);
    const {total_distance, setTotal_distance} = useContext(UserContext) as {total_distance: number, setTotal_distance: (value: number) => void};





    useEffect(() => {   
        if (boc) {
            setListerentransaction(true);
            console.log('boc изменился:', boc);
            watchDistanceChanges(AdressNftItemGlobal, distans1);
        }
    }, [boc]);



    function getContentOffchain(str: string){
        let Builder  = beginCell()       
        Builder.storeStringRefTail(str)
        let itemContent = Builder.endCell();    
        return itemContent;
    }
      
    async function mint () {
        if (!wallet || !sender || !client) {
            throw new Error('Кошелек не подключен');
        }

        try {
           
            
            const contractopen = NftContractsecond.fromAddress(Address.parse('kQBZUrv3Dv8xMmQ1VVMrv37mACT2sDHLyKx3AgV_5yNucNkc'));
            const contracts = client.open(contractopen) as OpenedContract<NftContractsecond>;
            //address nft function
            let contentUrl = getContentOffchain("d4e0u8pv5heapkk6anr6");
            
            const message: Mint = {
                $$type: 'Mint',
                query_id: 1n,
                custom_payload: contentUrl
            };

            const result = await contracts.send(
                sender as Sender,
                {
                    value: toNano('0.09')
                },
                message
            ).catch((error) => {
                if (error.message?.includes('Transaction was not sent')) {
                    throw new Error('USER_CANCELED_TRANSACTION');
                }
                throw error;
            });

            return result;

        } catch (error: any) {
            console.error('Ошибка при минтинге:', {
                message: error.message,
                code: error.code
            });
            throw error;
        }
       
       
          
         

    }

            async function sendMintVoucher(value: number){
                console.log('sendMintVoucher', value) 

                if (!wallet || !sender || !client) {
                    throw new Error('Кошелек не подключен');

                }

                try {
                    
                   const contractopen = NftVaucher.fromAddress(Address.parse('EQC1_JExO6dVdtle7__5ypc-bIRrsdk7D5fcsQP5_FFoz2e6'));
                   const contracts = client.open(contractopen) as OpenedContract<NftVaucher>;
                  let contentUrl = getContentOffchain("d4emaoha8bq0r59r0blk");                    
                    
                    
                    const message: MintVaucher = { 
                        $$type: 'Mint',             
                        query_id: 1n,
                        custom_payload: contentUrl,
                        count: BigInt(value)         
                    };

                    const result = await contracts.send(
                        sender as Sender,
                        {
                            value: toNano('0.09')
                        },
                        message
                    ).catch((error) => {
                        if (error.message?.includes('Transaction was not sent')) {
                            throw new Error('USER_CANCELED_TRANSACTION');
                        }
                        throw error;
                    });

                    return result;

                } catch (error: any) {
                    console.error('Ошибка при минтинге:', {
                        message: error.message,
                        code: error.code
                    });
                    throw error;
                }
            
               






            }


    async function sendActivateVoucher(addressVaucher: string, addressNft: string){
        
        const AdressVaucher = NftItemVaucher.fromAddress(Address.parse(addressVaucher));
        const ContractVaucher = client.open(AdressVaucher) as OpenedContract<NftItemVaucher>;
        const message: Activate = {
            $$type: 'Activate',             
            query_id: 10n,
            item_address: Address.parse(addressNft)
        }
        const result = await ContractVaucher.send(
            sender as Sender,
            {
                value: toNano('0.09')
            },
            message
        );
    }


    async function sendNewMeta(address: string){      
            if (!wallet || !sender || !client) {
                throw new Error('Кошелек не подключен');
            }
    
        setAdressNftItemGlobal(address);



        const AdressNftItem = NftItem.fromAddress(Address.parse(address));
        const NftItemcontract = client.open(AdressNftItem) as OpenedContract<NftItem>;

        
            // Отправляем сообщение с подписью
        const message: UpdateMeta   = {
            $$type: 'UpdateMeta',             
            query_id: 10n, 
            content: beginCell().endCell()
        }
       
        const tx = await NftItemcontract.send(
            sender,
            {
                value: toNano('0.5')
            },
            message
        );
        
        
    }
    

    async function getRandomBytes(length: number): Promise<Uint8Array> {
        return crypto.getRandomValues(new Uint8Array(length));
    }
    

    async function getSignature(address: string, tokenAmount: number, userId: string) {
        const response = await fetch('https://drivecoin.io/api/process-tokens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'charset': 'utf-8'
              },
            body: JSON.stringify({
                Address: address,
                tokenAmount: tokenAmount,
                userId: userId
            })
        });
        const data = await response.json();
        return data;
    }
        async function returnToken(tokenAmount: number, userId: string, statisticId: string) {
            const response = await fetch('https://drivecoin.io/api/check-and-revert-transaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'charset': 'utf-8'
                },
                body: JSON.stringify({ 
                    statisticId: statisticId,                   
                    tokenAmount: tokenAmount,
                    userId: userId
                })
            });
            const data = await response.json();
            return data;
        }



    async function sendBoost(address: string, boostValue: number, userId: string){      
        if (!wallet || !sender || !client) {
            throw new Error('Кошелек не подключен');
        }
   
     setAdressNftItemGlobal(address);



    const AdressNftItem = NftItem.fromAddress(Address.parse(address));
    const NftItemcontract = client.open(AdressNftItem) as OpenedContract<NftItem>;

    const distans1 = await NftItemcontract.getGetGameAttributes();
    setDistans1(distans1);


    console.log('distans:', distans1)
    
    const boostValuesend = BigInt(boostValue);
    console.log('data:', boostValuesend)
    const msg = await getSignature(address, boostValue,  userId ).then((data) => {
        console.log('data:', data)
        return data
    })
    console.log('nonce:', msg.signature.nonce)
    console.log('signature:', msg.signature.signature)
    console.log('publicKey:', msg.signature.publicKey)

        // Отправляем сообщение с подписью
    const message: SetGameAttributes = {
            $$type: 'SetGameAttributes',             
            query_id: 10n,
            distans: boostValuesend,
            nonce: BigInt(msg.signature.nonce),
            signature: beginCell()
                .storeBuffer(Buffer.from(msg.signature.signature, 'hex'))
                .endCell()
                .beginParse(),
            public_key: BigInt('0x' + msg.signature.publicKey),
            collection_fee: toNano('0.005')
        }
        try{
              
            const tx = await NftItemcontract.send(
                sender,
            {
                value: toNano('0.01')
            },
            message
        );
        setTotal_distance(total_distance - boostValue);

        }catch(error){
            
           toast.error('Error send boost');
           returnToken(boostValue, userId, msg.signature.statisticId);
          
        }
        
        
    }
  
    async function watchDistanceChanges(address: string, distans1: GameAttributes) {
        if (!wallet || !sender || !client || !address) {
            console.log('Необходимые параметры не определены');
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
          console.log('start watchDistanceChanges', address, distans1)
        try {
            const AdressNftItem = NftItem.fromAddress(Address.parse(address));
            const NftItemcontract = client.open(AdressNftItem) as OpenedContract<NftItem>;
            
            const startTime = Date.now();
            const timeout = 30000; // 30 секунд

            while (Date.now() - startTime < timeout) {
               
                const distans2 = await NftItemcontract.getGetGameAttributes();
                console.log('получаем distans2:', distans1.distans , 'distans2:', distans2.distans)
                if (distans2.distans !== distans1.distans) {
                    console.log('distans2 !== distans1', distans2, distans1)
                    break;
                }
                await new Promise(resolve => setTimeout(resolve, 6000));
            }
        } catch (error) {
            console.error('Ошибка при проверке дистанции:', error);
        }
    }





    return {
        mint,
        sendBoost, sendNewMeta, sendMintVoucher, sendActivateVoucher
        
     }


    
}
