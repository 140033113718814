import { CHAIN, useTonConnectUI, useTonWallet, useTonAddress,
  useTonConnectModal, WalletsModal , TonConnectUI, TonConnectUIReactError, 
  UserActionEvent} from "@tonconnect/ui-react";
import { ConnectEventError, ConnectEventSuccess } from "@tonconnect/protocol";
import { SenderArguments, Address, Sender, Cell } from "@ton/core";
import { toast } from "react-toastify";
import { useState } from "react";

export function useTonConnect(): {
    sender: Sender;
    connected: boolean;
    wallet: string | null;
    network: "Mainnet" | "Testnet" | null;
    tonConnectModal: Omit<WalletsModal, "onStateChange">;
    tonAddress: string | null;
    tonConnectUI: any;
    boc: string | null; 
   
    
} {
    const [tonConnectUI] = useTonConnectUI()
    



    const [boc, setBoc] = useState<string | null>(null)
    const wallet = useTonWallet()
    
    //const queryId = wallet?.queryId
    //console.log(wallet)
    const tonConnectModal = useTonConnectModal()
    const tonAddress = useTonAddress(true)
    const handleConnect = (e: ConnectEventSuccess) => {
      console.log('Wallet connected success:', e);
    
  };
  const handleUserAction = (e: UserActionEvent) => {
    switch (e.type) {
      
        case 'transaction-sent-for-signature':
            console.log('Транзакция:', e);
           
            break;
            
       
    }
    handleUserAction(e)
    

};

    
    return {
        sender: {
            send: async (args: SenderArguments) => {
             
              try {
                
                   const resultTransaction =  await tonConnectUI.sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString("base64"),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000,
                    },
                  );
                    // window.location.reload
                  setBoc(resultTransaction.boc)
                 
                  
                } catch (error: any) {
                    const errorMessage = new TonConnectUIReactError(error);
                    throw error;
                }
               // toast.success('Send transaction success', )
            },
            address: wallet?.account?.address ? Address.parse(wallet?.account?.address as string) : undefined
          }, 

        connected: !!wallet?.account.address,
        wallet: wallet?.account.address ?? null,
        network: wallet?.account.chain as CHAIN === CHAIN.MAINNET ? "Mainnet" : "Testnet",
        tonConnectModal, 
        tonAddress,
        tonConnectUI, 
        boc
    }
}