import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { ChevronRight } from 'lucide-react';
import { CustomModal } from './CustomModalReact.js';

function ListGroupItem({ items }) {
  const [modalStates, setModalStates] = useState(
    items.map(() => false) // Инициализируем массив состояний
  );

  const setIsModalOpen = (index, value) => {
    setModalStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  };

  return (
    <>
      <div className="space-y-4">
        {items.map((item, index) => (
          <div
            onClick={() => setIsModalOpen(index, true)}
            key={index}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] p-2 rounded-lg"
          >
            <div className="flex items-start">
              <Image
                src={require('../img/' + item.img)}
                roundedCircle
                className="w-12 h-12 object-cover mr-4 rounded-full"
              />
              <div>
                <h2 className="text-md text-white">{item.title}</h2>
                {item.text && <p className="text-sm text-gray-400">{item.text}</p>}
              </div>
              <ChevronRight className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        ))}
        {items.map((item, index) => (
         
            <CustomModal
              key={`modal-${index}`}
              isOpen={modalStates[index]}
              onClose={() => setIsModalOpen(index, false)}
            >
              {item.desk}
            </CustomModal>
          
        ))}
      </div>
    </>
  );
}

export default ListGroupItem;
