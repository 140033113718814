import React, { useState } from 'react';
import { Card, CardContent } from './ui/card.tsx';
import { ChevronRight, Info, HelpCircle, ShoppingBag } from 'lucide-react';
import { CustomModalReact, CustomModal } from './CustomModalReact.js';
import Getcontext from './Getcontext.js';

const iconMap = {
  Info: Info,
  HelpCircle: HelpCircle,
  ShoppingBag: ShoppingBag,
};




export function InfoCards({cards}) {
  const [modalStates, setModalStates] = useState(
    cards.map(() => false) // Инициализируем массив состояний
  );

  const setIsModalOpen = (index, value) => {
    setModalStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  };

  return (
    <>
      <div className="space-y-4">
        {cards.map((card, index) => {
          const IconComponent = iconMap[card.icon];
          return (
          <Card
            key={index}
            onClick={() => setIsModalOpen(index, true)}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)]"
          >
            <CardContent className="p-0">
              <div
                className={card.image ? "h-48 w-full bg-cover bg-center" : ""}
                style={card.image ? { backgroundImage: `url(${require(`../img/${card.image}`)})` } : {}}
              />
              <div className="p-4">
                <div className="mb-2 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    
                    <IconComponent className="h-5 w-5 text-violet-400" />
                    <h3 className="text-lg font-semibold text-white">{card.title}</h3>
                  </div>
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                </div>
                <p className="text-sm text-gray-400">{card.description}</p>
              </div>
            </CardContent>
            </Card>
          );
        })}
      </div>
      {cards.map((card, index) => (
        
          <CustomModalReact
            key={`modal-${index}`}
            isOpen={modalStates[index]}
            onClose={() => setIsModalOpen(index, false)}
          >
            <p className="text-xl font-bold">{card.title}</p>
            <div dangerouslySetInnerHTML={{ __html: card.description_all }} />

          </CustomModalReact>
        
      ))}
    </>
  );
}

export default InfoCards;