import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CarouselComponent = () => {
  const [index, setIndex] = useState(1); // Начинаем с второго элемента

 const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  }; 
  const element = <FontAwesomeIcon icon={faArrowRightToBracket} />
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect} 
      controls={true}
      indicators={false}
      touch={true}
      interval={null} // Отключаем автоматическую прокрутку
      nextIcon={element}
      prevIcon={null}
      wrap={true}
      nextLabel="true" // Включаем анимацию слайда
    >
      <Carousel.Item style={styles.carouselItem} >
        <div style={styles.imageContainer}>
          <img src={require('../img/green.png')} alt="Card 1" style={styles.image} />
          <div style={styles.overlay}>
            <h3 style={styles.title}>Card Title 1</h3>
            <div style={styles.buttonContainer}>
              <Button variant="outline-light" style={styles.button}>Go somewhere</Button>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item style={styles.carouselItem}>
        <div style={styles.imageContainer}>
          <img src={require('../img/yellow.png')} alt="Card 2" style={styles.image} />
          <div style={styles.overlay}>
            <h3 style={styles.title}>Card Title 2</h3>
            <div style={styles.buttonContainer}>
              <Button variant="outline-light" style={styles.button}>Go somewhere</Button>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item style={styles.carouselItem}>
        <div style={styles.imageContainer}>
          <img src={require('../img/red.png')} alt="Card 3" style={styles.image} />
          <div style={styles.overlay}>
            <h3 style={styles.title}>Card Title 3</h3>
            <div style={styles.buttonContainer}>
              <Button variant="outline-light" style={styles.button}>Go somewhere</Button>
            </div>
          </div>
        </div>
      </Carousel.Item>

      {/* Добавьте больше элементов Carousel.Item по мере необходимости */}
    </Carousel>
  );
};

const styles = {
  carouselItem: {  
    width: '100%',  
    justifyContent: 'center',
    padding: '0 2px', // Отступы для видимости соседних карточек
  },
  imageContainer: {
    
    // Ширина контейнера для видимости соседних карточек
    position: 'relative',
    borderRadius: '25px', // Закругленные углы
    overflow: 'hidden', // Обрезка изображения по границам
  },
  image: {
    width: '100%',
    height: 'auto',
    
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Полупрозрачный фон для читаемости текста
  },
  title: {
    color: 'white',
    marginBottom: '5px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    borderColor: 'white',
    color: 'white',
    backgroundColor: 'transparent',
    borderRadius: '25px'
  },
};

export default CarouselComponent;
