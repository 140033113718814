import React from 'react';

export function CustomModalReact({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose} style={{height:'85dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
export function CustomModalReactFriend({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose} style={{height:'40dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
export function CustomModalReactWallet({ isOpen, onClose, children }) {
    return (
        <div className={ `modalmy ${isOpen ? 'showmy ' : ''} z-15`} onClick={onClose} style={{height:'40dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
export function CustomModalReactNft({ isOpen, onClose, children }) {
    return (
        <div className={ `modalmy ${isOpen ? 'showmy ' : ''}`} onClick={onClose} >
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}> 
                <div className=""><span className="close-buttonmy" onClick={onClose}>&times;</span></div>
                
                    {children}
                
            </div>
        </div>
    );
}
export function CustomModalMintNft({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy ' : ''} z-10`} onClick={onClose}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}> 
                <div className="fixed top-0 right-0 left-0">
                    <div>
                        <div 
                            style={{borderRadius: '25px 25px 0 0'}}   
                            className="mb-[50px] bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 flex justify-center bg-black rounded-lg p-4 text-white text-lg font-bold"
                        >
                            Mint New NFT
                        </div>
                        <div className="close-buttonmy flex justify-end" onClick={onClose}>
                            &times;
                        </div> 
                    </div>
                </div>  
                {children}
            </div>
        </div>
    );
}
export function CustomModal({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose} style={{height:'85dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div dangerouslySetInnerHTML={{ __html: children }} />
            </div>
        </div>
    );
}

export default CustomModalReact;
