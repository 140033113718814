import {useTonConnect} from "./useTonconnect.ts";
import { UserContext } from "../UserContext.js";
import { useContext } from "react";
import { Address } from "@ton/core";

interface NftItem {
    collection?: {
        address: string;
    };
    [key: string]: any;
}

export default function useNft() {
    const {wallet, network} = useTonConnect();
    const {setNfts} = useContext(UserContext) as {setNfts: (nfts: any[]) => void};

    const fetchNfts = async () => {
        let link = '';
        if (network === 'Testnet') {
            link = `https://testnet.tonapi.io/v2/accounts/${wallet}/nfts`;
        } else {
            link = `https://tonapi.io/v2/accounts/${wallet}/nfts`;
        }
        
        try {
            const response = await fetch(link, {
                headers: {
                    'Authorization': `Bearer AGHVJNG57RBRJAIAAAADQCFQIS4AUEOXRI6DYLIJVWS33MIESA3RENAUNTCE4G4YGH5T67Q`,
                    'Accept': 'application/json',
                }
            });
            
            const data = await response.json();
            
            const allowedCollections = [
                "0:5952bbf70eff3132643555532bbf7ee60024f6b031cbc8ac7702057fe7236e70",
            ];
            const allowedVaucher = [
                "0:b5fc91313ba75576d95eeffff9ca973e6c846bb1d93b0f97dcb103f9fc5168cf"                
            ];

            const filteredCollectionNfts = data.nft_items.filter((nft: NftItem) => 
                allowedCollections.includes(nft.collection?.address)
            );
            
            const filteredVoucherNfts = data.nft_items.filter((nft: NftItem) => 
                allowedVaucher.includes(nft.collection?.address)
            );
            
            const processedCollectionNfts = filteredCollectionNfts.map(nft => ({
                ...nft,
                type: 'nft'
            }));
            
            const processedVoucherNfts = filteredVoucherNfts.map(nft => ({
                ...nft,
                type: 'voucher'
            }));
            
            const allNfts = [...processedCollectionNfts, ...processedVoucherNfts];
            
            setNfts(allNfts);
            return allNfts;
            
        } catch (error) {
            console.error("Ошибка при получении токенов:", error);
            return [];
        }
    }
 
    return { fetchNfts }; 
}
