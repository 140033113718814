import React from 'react';
import contextData from './context/content.json';

const contentComponent = ({ language, contextKey }) => {
  const languageData = contextData.context[language];
  if (!languageData) {
    console.error(`No data found for language: ${language}`);
    return [];
  }

  const content = languageData[contextKey];
  if (!content) {
    console.error(`No content found for key: ${contextKey}`);
    return [];
  }

  return content;
};

export default contentComponent;