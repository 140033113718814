import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from '../UserContext.js';

import { Gauge } from 'lucide-react';
import Getcontext from './Getcontext.js';




function CoinDrive({ name, price }) {
  
  const [data, setData] = useState([]);
  const { distance, setDistance, total_distance, setTotal_distance , user, setUser } = useContext(UserContext);
  //if (!user) {setUser({id: 5000535804})}
  const element = Getcontext({ language: localStorage.getItem('language_code') || 'en', contextKey: 'element' });



  return (
    <div className="mb-6 overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
    <div className="mb-4 flex items-center justify-between">
      <h3 className="text-lg font-medium text-gray-200">{element.current_amount}</h3>
     
      <Gauge className="h-6 w-6 text-violet-400" />
    </div>
    <div className="text-4xl font-bold text-white">
    {total_distance ? total_distance : '0.00'} <span className="text-sm text-gray-400">{element.tokens}</span>
    </div>
    <div className="mt-2 text-sm text-gray-400">{element.total_boost}</div>
  </div>
        
    
   
       
        
    // <div style={{ ...styles.container, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //     <div style={{ ...styles.imgcontainer, display: 'flex', justifyContent: 'center', position: 'relative' }}>
    //         <img src={require('../img/speedometr.png')} alt="telegram" style={{ ...styles.img, ...styles.breathingGlow }} />
    //         <div style={{ ...styles.price, position: 'absolute', top: '50%', transform: 'translateY(50%)' }}>
    //             <FontAwesomeIcon icon={faGauge} style={styles.priceicon} />
    //             {total_distance ? total_distance : '0.00'} 
    //         </div>
    //     </div>
    // </div>

  );
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',  
    alignItems: 'center',
   padding: '50px',
   
  },
  price: {
    fontSize: '24px',
  fontWeight:"700",
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
 
    
  },
  title: {

    fontSize: '24px',
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  imgcontainer: {
   position: 'absolute',
   left: '0',
   right: '0',
   
   
  },
  img: {
 borderRadius: '50%',
 border: '2px solid #0085fa',
 boxShadow: ' 0 0 20px #0085fa, 0 0 40px #0085fa ',

},
  priceicon: {
    color: "white",
    fontSize: "24px",
    marginRight: "10px"
  },
  container1: {
    color: "yellow",
    textAlign: "center",   
    marginTop: "10px", 
    fontWeight: "bold",
  },
  lvl: {
    color: "white",
    fontWeight: "bold",
    fontSize: "18px"
  },
  lvlcontainer: {
    lineHeight: "34px",
    position: "relative",    
    color: "white",
    fontWeight: "normal",
    fontSize: "16px", 
    display: "flex",
    justifyContent: "left",     
    border: "0px solid white",
    borderRadius: "25px",
    padding: "0px 25px", 
    width: "fit-content",
   
  },
  '@keyframes breathing': {
    '0%': { boxShadow: '0 0 10px #0085fa' },
    '50%': { boxShadow: '0 0 40px #0085fa' },
    '100%': { boxShadow: '0 0 10px #0085fa' },
  },
  breathingGlow: {
    animation: 'breathing 7s infinite',
  },
};

export default CoinDrive;
