import React from 'react';
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import { Home, Gauge, Settings, HelpCircle, ShoppingBag, Handshake, Car } from 'lucide-react';
import Main from './Main.js';
import Task from './Task.js';
import Friend from './Friend.js';
import Assets from './Assets.js';
import Setting from './Setting.js';
import Clubs from './Clubs.js';
import contentComponent from './Getcontext.js';

export function BottomNav() {
  const location = useLocation();
  const language = localStorage.getItem('language_code') || 'en';
  const element = contentComponent({ language, contextKey: 'element' });

  const navItems = [
    { icon: Home, label: element.main, path: '/main' },
    { icon: Gauge, label: element.task, path: '/task' },
    { icon: Handshake, label: element.friend, path: '/friend' },
    { icon: ShoppingBag, label: element.assets, path: '/assets' },
    { icon: Car, label: element.clubs, path: '/clubs' },
  ];

  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Navigate to="/main" />} />
        <Route path="/main" element={<Main />} />
        <Route path="/task" element={<Task />} />
        <Route path="/friend" element={<Friend />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/clubs" element={<Clubs />} />
      </Routes>
      <nav className="fixed bottom-0 left-0 right-0 border-t border-violet-900/20 bg-gradient-to-t from-[#1a0b2e] to-[#1a0b2e]/95 backdrop-blur-lg">
        <div className="flex justify-around p-4">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`flex flex-col items-center space-y-1 transition-all duration-300 ${
                location.pathname === item.path ? 'text-violet-400' : 'text-gray-500 hover:text-violet-400'
              }`}
            >
              <item.icon className="h-6 w-6" />
              <span className="text-xs font-medium">{item.label}</span>
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default BottomNav;