import React, { useState, useEffect, useContext } from "react";

import contentComponent from "./Getcontext.js";
import {CustomModalReactWallet, CustomModalMintNft} from "./CustomModalReact.js";
import {BatteryCharging } from 'lucide-react';
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../UserContext.js";
import {useTonConnect} from "../Hook/useTonconnect.ts";
import useNft from "../Hook/useNft.ts";
import {useContractData} from "../Hook/useContractNft.ts";
import {useContractMint} from "../Hook/useContractNft.ts";
import {Address, fromNano} from "@ton/core";






const WalletConnectButton = () => {
    const language = localStorage.getItem('language_code');
    const element = contentComponent({ language: language, contextKey: 'element' });
    
    const {sender, connected, wallet, network, tonAddress , tonConnectUI, tonConnectModal} = useTonConnect(); 
    const {nfts, setNfts, total_distance, setTotal_distance, user , setIsLoading, Tabclick} = useContext(UserContext);
    const {fetchNfts} = useNft();
    
    const [showModal, setShowModal] = useState(false);
    const [showModalMintMenu, setShowModalMintMenu] = useState(false);
    const [showModalMintVoucher, setShowModalMintVoucher] = useState(false);

    const [showModalMint, setShowModalMint] = useState(false);
    const [selectedNftIndex, setSelectedNftIndex] = useState(false);

    const [nft_content, setNftContent] = useState("");
    const [nftUpdate, setNftUpdate] = useState(nfts);
    const [boostValue, setBoostValue] = useState('0');
    const [isExpanded, setIsExpanded] = useState(false);
    const [boostValueVaucher, setBoostValueVaucher] = useState(250);
    const [showModalChooseVoucher, setShowModalChooseVoucher] = useState(false);
    const [nftActivateVoucher, setNftActivateVoucher] = useState();
    const [mathboostValue, setMathboostValue] = useState(0);
    
    const [nftHash, setNftHash] = useState();



    let {next_item, nextItemIndex, refetch} = useContractData();
    
    let {mint, sendBoost, sendNewMeta, sendMintVoucher, sendActivateVoucher} = useContractMint();
    
    const handleCloseMintMenu = () => setShowModalMintMenu(false);
    const handleCloseChooseVoucher = () => setShowModalChooseVoucher(false);
    const handleCloseMintVoucher = () => setShowModalMintVoucher(false);
    const handleClose = () => setShowModal(false);
    const handleCloseMint = () => setShowModalMint(false);
    const handleCloseNft = () => setSelectedNftIndex(false);
    const functionsetShowModalChooseVoucher = (nft_address) => {
        setShowModalChooseVoucher(true);
        setNftActivateVoucher(nft_address);
    }

    useEffect(() => {
        setNftHash(nfts);
        console.log('изменился nftHash:', nftHash);
    }, [nfts]);






    const truncateAddress = (address) => {
        if (!address) return '';
        try {
            // Преобразуем в дружественный формат
            if (typeof address === 'string') {
                const friendlyAddress = Address.parse(address).toString({
                    testOnly: network === 'Testnet',
                    bounceable: true,
                    urlSafe: true
                });
                return `${friendlyAddress.slice(0, 4)}...${friendlyAddress.slice(-4)}`;
            }
            // Для объекта Address
            if (address instanceof Address) {
                const friendlyAddress = address.toString({
                    testOnly: network === 'Testnet',
                    bounceable: true,
                    urlSafe: true
                });
                return `${friendlyAddress.slice(0, 4)}...${friendlyAddress.slice(-4)}`;
            }
            return '';
        } catch (error) {
            console.error('Error formatting address:', error);
            return '';
        }
    };
    
    const truncateString = (str, maxLength = 20) => {
        if (!str) return '';
        return str.length <= maxLength ? str : `${str.slice(0, maxLength)}...`;
    };
    
    const openTonviewer = (address) => {
        let chain = network === 'Testnet' ? 'testnet.' : '';
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.openLink(`https://${chain}tonviewer.com/${address}?section=nft`);
        }
    };

    const handleNftClick = (index) => {
        setSelectedNftIndex(true);
        setNftContent(nfts[index]);
        Tabclick();
    }
    
    const copyTonAddress = (tonAddress) => {
        navigator.clipboard.writeText(tonAddress);
        toast.info('Ton address copied to clipboard',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
        });
    }
    
    const disconnectWallet = async () => {
        setShowModal(false);
        handleClose();
        console.log('disconnecting wallet');
        try{
        await  tonConnectUI.disconnect();
        setNfts([]);
       
        }catch(error){
            console.log(error);
        }


    };
    


    
    const DescriptionComponent = ({ text, maxLines = 3 }) => {
        if (!text) return null;
        
        const words = text.split(' ');
        const averageWordsPerLine = 10;
        const shouldShowButton = words.length > maxLines * averageWordsPerLine;
        const truncatedWords = isExpanded ? words : words.slice(0, maxLines * averageWordsPerLine);
        
        return (
            <div className="text-sm text-left text-gray-400 overflow-hidden p-1">
                <div className="relative">
                    <p className={`mb-2 ${!isExpanded && 'line-clamp-3'}`}>
                        {truncatedWords.join(' ')}
                    </p>
                    {shouldShowButton && (
                        <button 
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="text-sm bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] bg-clip-text text-transparent font-bold"
                        >
                            {isExpanded ? 'Hide' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        );
    };
    
    const sendMint = async () => {
        try {
            if (!connected) {
                toast.error('Please connect your wallet');
                return;
            }

            await toast.promise(
                (async () => {
                    handleCloseNft();
                    await mint();
                    await CheckNftsUpdate();
                })(),
                {
                    pending: 'Creating NFT...',
                    success: 'NFT created successfully!',
                    error: {
                        render({data}) {
                            return `NFT creation error: ${data?.message || 'Unknown error'}`;
                        }
                    }
                }
            );

        } catch (error) {
            if (error.message === 'USER_CANCELED_TRANSACTION') {
                toast.info('Transaction canceled');
                return;
            }
            console.error('Error:', error);
        }
    };
    
    const sendBoostNft = async (address, boostValue) => {
        if (!boostValue) {
            toast.error('Please select a value to boost NFT');
            return;
        }

        try {
            await toast.promise(
                sendBoost(address, boostValue, user.user.id),
                {
                    pending: 'Boosting NFT...',
                    success: {
                        render({data}) {
                            setSelectedNftIndex(false);
                            setIsLoading(true);
                            
                            
                            const initialNftsLength = nfts.length;
                            CheckNftsUpdate();

                            return `NFT successfully boosted!`;
                        }
                    },
                    
                    
                    
                    
                    error: {
                        render({data}) {
                            return `NFT boost error: ${data?.message || 'Unknown error'}`;
                        }
                    }
                }
            );

          

        } catch (error) {
            console.error('Ошибка при усилении NFT:', error);
        }
    };
    const sendMintVoucherNew = async (value) => {
        toast.promise(
            sendMintVoucher(value),
            {
                pending: 'Minting voucher...',
                success: 'Voucher minted successfully!',
                error: 'Error minting voucher'
            }
        );
        
        setShowModalMintVoucher(false);
        const initialNftsLength = nfts.length;
        CheckNftsUpdate();
    };

    const sendActivateVoucherNow = async (addressvaucher, addressNft) => {
        setShowModalChooseVoucher(false);
        handleCloseNft();
        
        toast.promise(
            sendActivateVoucher(addressvaucher, addressNft),
            {
                pending: 'Activating voucher...',
                success: 'Voucher activated successfully!',
                error: 'Error activating voucher'
            }
        ).then(() => {
            setShowModalChooseVoucher(false);
            
            CheckNftsUpdate();
        });
    };
   
   

   useEffect(() => {
    setMathboostValue(Math.floor(total_distance * boostValue / 100));
   }, [boostValue]);
 
    
    
    
   

    useEffect(() => {
        if (connected && nfts.length === 0) {
            fetchNfts();
        }
        console.log('next_item:', next_item);
    }, [connected, next_item]);
     
    
   const openTosty = (text) => {
    toast.promise(
        new Promise((resolve) => setTimeout(resolve, 3000)),
        {
            pending: 'Promise is pending...',
            success: 'Promise resolved 👌',
            error: 'Promise rejected 🤯'
        }
    );
};

    // Добавить функцию для открытия ссылки в Telegram
    

    const voucherImages = {
        250: require('../img/nft_image/250dp.jpg'),
        500: require('../img/nft_image/500dp.jpg'),
        1000: require('../img/nft_image/1000dp.jpg'),
        5000: require('../img/nft_image/5000dp.jpg')
    };

    async function CheckNftsUpdate () {
        let attempts = 0;
        const maxAttempts = 10;
        const interval = 10000;
        
        const createNftHash = async (nftsArray) => {
            let nftString = nftsArray
                .map(nft => ({
                    address: nft.address,
                    metadata: {
                        name: nft.metadata.name,
                        attributes: nft.metadata.attributes,
                        description: nft.metadata.description
                    },
                    owner: nft.owner.address,
                    type: nft.type
                }))
                .sort((a, b) => a.address.localeCompare(b.address))
                .map(nft => JSON.stringify(nft))
                .join('|');
            const encoder = new TextEncoder();
            const data = encoder.encode(nftString);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        };

        const initialHash = await createNftHash(nfts);

        const check = async () => {
            if (attempts >= maxAttempts) {
                setIsLoading(false);
                toast.error('Не удалось обновить NFT. Попробуйте позже');
                return;
            }

            try {
                const freshNfts = await fetchNfts();
                await refetch();
                
                let currentHash = await createNftHash(freshNfts);
                
                if (currentHash !== initialHash) {
                    setIsLoading(false);
                    toast.success('NFT успешно обновлены');
                    return;
                }

                attempts++;
                setTimeout(check, interval);
            } catch (error) {
                console.error('Error checking NFTs:', error);
                attempts++;
                setTimeout(check, interval);
            }
        };

        setIsLoading(true);
        check();
    }

    return (
        
        <div>
          
            {connected ? (
               <>
                <div
                    className="text-white cursor-pointer text-center "
                    
                >
                    <span  onClick={() => setShowModal(true)} className=" border border-gray-400 border-1 rounded-3xl px-4  py-1 flex items-center w-fit mx-auto justify-center">
                        
                        <span className="mr-2">{network} </span>
                        <img src={require('../img/ton_gd.png')} alt="wallet icon" className="w-4 h-4 mr-2 bg-white rounded-full overflow-hidden" />
                        {truncateAddress(tonAddress)}

                    </span>
                    
                </div>
                <button 
                className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 z-10"
                onClick={() => setShowModalMintMenu(true)}
               >
                Mint NFT 
           </button>
                </>
                 
            ) : (
                <button className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
                    onClick={() => tonConnectModal.open()}>
                     Connect Wallet 
                </button>
            )}
            <div className="flex justify-center items-center mb-20 pb-20">

            {connected && nfts.length > 0 ? (
            <div>
                <h1 className="text-left text-white text-2 mt-4">Your NFTs</h1>
                <ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-4">
                    {nfts.filter(nft => nft.type === 'nft').map((nft, index) => (
                        <li key={index} onClick={() => handleNftClick(index)} data-index={index} className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                            <div className="relative">
                                <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                                
                                {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance') && (
                                <div className="absolute top-2 right-2  bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5 ">
                                    <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                        {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '-'}
                                    </span>
                                </div>
                                )}
                            </div>
                            <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                            
                        </li>
                    ))}
                </ul>
                <h1 className="text-left text-white text-2 mt-4">Your Voucher</h1>
                <ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-4">
                    {nfts.filter(nft => nft.type === 'voucher').map((nft, index) => (
                        <li key={nft.index} onClick={() => handleNftClick(index + nfts.filter(nft => nft.type === 'nft').length)} data-index={index} className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                            <div className="relative">
                                <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                                
                                {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance') && (
                                <div className="absolute top-2 right-2  bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5 ">
                                    <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                        {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '-'}
                                    </span>
                                </div>
                                )}
                            </div>
                            <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                            
                        </li>
                    ))}
                </ul>
            </div>
            )
                :
            connected && (
            <div className="text-left text-sm mb-4 text-gray-400 mt-4 p-2 space-y-4">
                <div>                    
                    <p className="font-bold text-base mb-2 text-center">Why You Need ⚡️ an NFT in This WEB3 Game!</p>
                </div>

                <div className="bg-gradient-to-br from-[#2e1065]/30 to-[#4c1d95]/30 p-4 rounded-lg">
                    <p className="mb-4">
                        Owning an NFT is essential in this game! It allows you to:
                        <ul className="list-disc list-inside mt-2 space-y-1">
                            <li>Accumulate boosts 🚀 for your car's mileage</li>
                            <li>Participate in prize draws in TON</li>
                            <li>Transfer to other players while retaining characteristics</li>
                        </ul>
                    </p>

                    <p className="mb-4">
                        Mint your first NFT from the exclusive ✨ launch collection dedicated to the start of the game and receive a gift: 
                        <span className="font-bold text-indigo-400"> x1.2 mileage boost 🚀</span>.
                    </p>

                    <p className="italic">
                        This opportunity is a special reward 🎁 exclusively ✨ for the first participants of our game! Don't miss out!
                    </p>
                </div>
            </div>
            )}   
        </div>
        
        <ToastContainer          
        position="top-center"
        autoClose={2000}        
        theme="dark"        
        /> 
        
        <CustomModalReactWallet isOpen={showModal} onClose={handleClose}> 
            <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
                <button onClick={() => copyTonAddress(tonAddress) } className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                    Copy Ton Address
                </button>
                <button onClick={disconnectWallet } className=" text-gray-400 rounded-lg p-2 w-3/4">
                    Disconnect Wallet
                </button>            
            </div>
        </CustomModalReactWallet>
        <CustomModalReactWallet isOpen={showModalMintMenu} onClose={handleCloseMintMenu}> 
            <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
                <button  onClick={() => { handleCloseMintMenu() ;setShowModalMint(true); }} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                   NFT
                </button>
                <button  onClick={() => { handleCloseMintMenu() ;setShowModalMintVoucher(true); }} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                    Voucher
                </button>
                           
            </div>
        </CustomModalReactWallet>
        <CustomModalReactWallet isOpen={showModalChooseVoucher} onClose={handleCloseChooseVoucher}> 
            <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-2 items-center w-full pl-2 pr-2  overflow-y-auto max-h-[30vh]">
            <div className=" fixed top-0 right-0 left-0     ">
                
                <div className="  ">
                <div style={{borderRadius: '25px 25px 0 0'}}  
                className=" mb-[50px]  bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50  flex justify-center bg-black rounded-lg p-4 text-white text-lg   font-bold ">Choose a Voucher</div>
                <div className="close-buttonmy flex justify-end" onClick={handleCloseChooseVoucher}>&times;</div>
                </div>
                
                
            </div>  


           <div><ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 ">
           { nfts.filter(nft => nft.type === 'voucher')
                .map((nft, index) => (
                    <li key={index} 
                        onClick={() => sendActivateVoucherNow(nft.address, nftActivateVoucher)} 
                        data-index={index} 
                        className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                        <div className="relative">
                            <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                            
                        </div>
                        <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                    </li>
                ))}
        </ul></div>
    </div>
</CustomModalReactWallet>
        
        
        <CustomModalMintNft isOpen={showModalMint} onClose={handleCloseMint}>
            <div className="w-full justify-center content-center items-center p-4 overflow-y-auto max-h-[100vh] mb-[10%]">
                <>
                    <img
                        src={ require('../img/nft_image/carNft_one.jpg')}                        
                        className="w-full mt-2 rounded-t-lg"
                    />
                    <div className="w-full mx-auto mt-4">
                        <h1 className="text-lg text-left text-white-400 overflow-hidden p-1">Drive to Earn NFT Collection</h1>
                       
                            <DescriptionComponent maxLines={3} text="
                                The first limited collection of 1000 NFTs that allows you to power up your
                                crypto asset's energy with blockchain data recording and receive a boost to 
                                your car's mileage in return. NFT holders get the right to participate in daily TON 
                                prize draws, which constitute a percentage of the app's revenue. When transferred to 
                                another wallet, the boost is preserved.
                              " />
                       
                    </div>
                    <div className="w-full mx-auto mt-4">
                        <button onClick={() => {sendMint(); handleCloseMint(); }} className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                            Mint NFT
                        </button>
                    </div>
                </>
            </div>
        </CustomModalMintNft>
        
        <CustomModalMintNft isOpen={showModalMintVoucher} onClose={handleCloseMintVoucher}>
            <div className="w-full justify-center content-center items-center p-4 overflow-y-auto max-h-[100vh] mb-[10%]">
                <>
                    <img
                        src={voucherImages[boostValueVaucher] || require('../img/nft_image/250dp.jpg')}                        
                        className="w-full mt-2 rounded-t-lg"
                        alt={`Voucher ${boostValueVaucher} dp`}
                    />
                    <div className="w-full mx-auto mt-4">
                        <h1 className="text-lg text-left text-white-400 overflow-hidden p-1">Voucher</h1>
                       
                            <DescriptionComponent maxLines={3} text="
                                The Voucher is a limited collection of 1000 NFTs that allows you to power up your
                                crypto asset's energy with blockchain data recording and receive a boost to 
                                your car's mileage in return. NFT holders get the right to participate in daily TON 
                                prize draws, which constitute a percentage of the app's revenue. When transferred to 
                                another wallet, the boost is preserved.
                              " />
                       
                    </div>
                    <div className="w-full mx-auto mt-4">
                        
                            <button 
                                disabled={total_distance < boostValueVaucher}
                                onClick={() => {
                                    console.log('total_distance:', total_distance);
                                    console.log('boostValueVaucher:', boostValueVaucher);
                                    if (total_distance >= boostValueVaucher) {
                                        sendMintVoucherNew(boostValueVaucher);
                                    } else {
                                        toast.error(`Недостаточно dp. Необходимо: ${boostValueVaucher}, доступно: ${total_distance}`);
                                    }
                                }}  
                                style={total_distance >= boostValueVaucher ? 
                                    {opacity: 1, cursor: 'pointer'} : 
                                    {opacity: 0.5, cursor: 'not-allowed'}
                                } 
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full"
                            >
                                Create Voucher {boostValueVaucher ? boostValueVaucher : 0} dp
                                {total_distance < boostValueVaucher && 
                                    <div className="text-xs mt-1">
                                        Необходимо набрать еще {boostValueVaucher - total_distance} dp
                                    </div>
                                }
                            </button>
                        </div>
                     

                        <div className='text-center pl-2 pr-2  mt-8  text-gray-400' style={{ fontSize: '12px' }}>
                        Choose a value vaucher (max {total_distance} dp)
                        </div>

                    <div className="mb-6 pr-2 pl-2 w-full">
    <input
        type="range"
        min="0"
        max="3"
        step="1"
        value={[ 250, 500, 1000, 5000].indexOf(parseInt(boostValueVaucher))}
        onChange={(e) => {
            const values = [ 250, 500, 1000, 5000];
            setBoostValueVaucher(values[e.target.value]);
        }}
        className="w-full h-2 rounded-lg appearance-none cursor-pointer 
        bg-gradient-to-r from-[#4f46e5] to-[#7c3aed]
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:h-4 
        [&::-webkit-slider-thumb]:w-6 m-2
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:bg-white
        [&::-webkit-slider-thumb]:shadow-lg
        [&::-webkit-slider-thumb]:shadow-indigo-500/50"
    />
    <div className="flex justify-between">
        
        <span className="text-xs text-gray-400 font-bold">250 dp</span>
        <span className="text-xs text-gray-400 font-bold">500 dp</span>
        <span className="text-xs text-gray-400 font-bold">1000 dp</span>
        <span className="text-xs text-gray-400 font-bold">5000 dp</span>
    </div>
</div>
                </>
            </div>
        </CustomModalMintNft>


        {nft_content && (
        <div className={ `modalmy ${selectedNftIndex ? '  showmy ' : ''}  max-h-[100vh]  pb-10 pt-10 z-10 `  }  >
            
            <div className=" fixed top-0 right-0 left-0     ">
                
                <div className="  ">
                <div style={{borderRadius: '25px 25px 0 0'}}  className=" mb-[50px]  bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50  flex justify-center bg-black rounded-lg p-4 text-white text-lg   font-bold ">{nft_content.metadata.name}</div>
                <div className="close-buttonmy flex justify-end" onClick={handleCloseNft}>&times;</div>
                </div>
                
                
            </div>  

            <div className=" w-full  justify-center content-center items-center p-4 overflow-y-auto max-h-[100vh] mb-[10%] ">
              
                    <> 
                   

                   

                        <img  
                            src={nft_content.previews?.[3]?.url || nft_content.metadata?.image || require('../img/ton_gd.png')} 
                            alt={nft_content.metadata?.name} 
                            className="w-full  mt-2 rounded-t-lg"
                        />
                        <div className="rounded-b-lg  p-2 bg-card text-card-foreground overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] ">
                        
                        
                        <p className="mb-2 flex items-center gap-2 text-md font-semibold text-white">
                        <BatteryCharging  size={32} className=" text-violet-400" />
                            
                           Power NFT : <span>{nft_content.metadata?.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '0 dp'} </span></p>
                        


                        </div>



                        <div className="w-full mx-auto mt-4">
                           <h1 className="text-lg text-left  text-white-400 overflow-hidden p-1">{nft_content.metadata.name}</h1>
                           {nft_content?.metadata?.description && (
                               <DescriptionComponent text={nft_content.metadata.description} />
                           )}
                        
                        </div>
                        {nft_content.type === 'nft' ? (
                        <div className="w-full mx-auto mt-4">
                            <button onClick={() => {sendBoostNft(nft_content.address, mathboostValue).then(() => setBoostValue(0))}}  style={!mathboostValue? {opacity: 0.5, cursor: 'not-allowed'} : {}} className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                            NFT Boost  {mathboostValue} dp
                            </button>
                        </div>):""}
                     
                        {nft_content.type === 'nft' ? (
                        <div className='text-center pl-2 pr-2  mt-8  text-gray-400' style={{ fontSize: '12px' }}>
                        Choose a value to boost your NFT (max {total_distance} dp)
                        </div> ) :""}

                        {nft_content.type === 'nft' ? (
                        <div className="mb-6 pr-2 pl-2  w-full">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                value={boostValue}
                                onChange={(e) => setBoostValue(e.target.value) }
                                className="w-full h-2  rounded-lg appearance-none cursor-pointer 
                                bg-gradient-to-r from-[#4f46e5] to-[#7c3aed]
                                [&::-webkit-slider-thumb]:appearance-none
                                [&::-webkit-slider-thumb]:h-4 
                                [&::-webkit-slider-thumb]:w-6 m-2
                                [&::-webkit-slider-thumb]:rounded-full
                                [&::-webkit-slider-thumb]:bg-white
                                [&::-webkit-slider-thumb]:shadow-lg
                                [&::-webkit-slider-thumb]:shadow-indigo-500/50"
                            />
                            <div className="flex justify-between  ">
                                <span className="text-xs text-gray-400   font-bold">0%</span>
                                <span className="text-xs text-gray-400  font-bold">25%</span>
                                <span className="text-xs text-gray-400  font-bold">50%</span>
                                <span className="text-xs text-gray-400   font-bold">75%</span>
                                <span className="text-xs text-gray-400   font-bold">100%</span>
                            </div>
                        </div>
                        ):""}
                        
                        
                        {nft_content.type === 'nft'  &&  nfts.filter(nft => nft.type === 'voucher').length > 0 ? (
                            <div>
                                <button onClick={() => {functionsetShowModalChooseVoucher(nft_content.address)}}  
                                    className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                    Apply Voucher
                                </button>
                            </div>
                        ) : ""}
                         {nft_content.type === 'nft'  &&  nfts.filter(nft => nft.type === 'voucher').length < 1 ? (
                            <div>
                                <button onClick={() => {window.open('https://testnet.getgems.io/collection/kQC1_JExO6dVdtle7__5ypc-bIRrsdk7D5fcsQP5_FFoz9ww', '_blank')}}
                                    className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                    Buy Voucher on GetGems
                                </button>
                            </div>
                        ) : ""}

                        {nft_content.type === 'voucher' && (
                            <div>
                                <button onClick={() => {window.open('https://testnet.getgems.io/collection/kQC1_JExO6dVdtle7__5ypc-bIRrsdk7D5fcsQP5_FFoz9ww', '_blank')}}
                                    className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                    Sell NFT Voucher on GetGems
                                </button>
                            </div>
                        )}

                        <div className="w-full mx-auto  mt-10 mb-4 bg-gradient-to-br  rounded-lg from-[#2e1065]/30 to-[#4c1d95]/30 mb-1">
                            <div className="flex justify-between items-center p-2 ">
                                    <span className="text-sm text-gray-400 font-medium ">Details</span>
                                    <span 
                                        className="text-sm text-right text-white font-medium underline cursor-pointer" 
                                        onClick={() => openTonviewer(nft_content.address)}
                                    > 
                                        Tonviewer
                                    </span> 
                                </div>
                            <div className="w-full  ">
                                <div className="flex justify-between items-center p-2 rounded-lg ">
                                    <span className="text-sm text-gray-400">Owner</span>
                                    <span className="text-sm text-right text-white font-medium cursor-pointer" onClick={() => copyTonAddress(nft_content.owner.address)}>{truncateAddress(nft_content.owner.address)}</span>
                                </div>
                                <div className="flex justify-between items-center p-2 rounded-lg ">
                                    <span className="text-sm text-gray-400">Contract address</span>
                                    {console.log('nft_content.address', nft_content)}
                                    <span className="text-sm text-right text-white font-medium  cursor-pointer" onClick={() => copyTonAddress(nft_content.address)}>{truncateAddress(nft_content.address)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mx-auto mb-4"></div>
                    </>
                
            </div>
               
            </div>)}
        </div>



       
    
    );
};

export default WalletConnectButton;