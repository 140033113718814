import React, { useState } from 'react';
import ListGroupItem from './ListGroupItem';
import { Button, Overlay } from 'react-bootstrap';
import BreathingButton from './ButtonMain.js';
import CarouselComponent from './CarouselComponent.js'; // Импортируем компонент карусели
import CardGridComponent from './CardGridComponent.js'; // Импортируем компонент карточек
import TelegramAccount from './TelegramAccount.js';
import CoinDrive from './CoinDrive.js';
import SwiperComponent from './SwiperComponent.js';
import ImageComponent from './ImageComponent.js';
import SwiperComponentBig from './SwiperComponentbig.js';
import ToggleSwitch from './swich.js';
import { CustomModal } from './CustomModalReact.js';
import { Zap, Info, HelpCircle, ShoppingBag } from 'lucide-react';
import InfoCards from './CardTailwin.js';
import Getcontext from './Getcontext.js';

function Main() {
 
const language = localStorage.getItem('language_code') || 'en';
const content = Getcontext( {language:language, contextKey:'main'});


console.log(content);
  return (

    
    <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
      <div> <TelegramAccount /></div>
      
      <CoinDrive />
      <div>
        {/* {<CarouselComponent /> } */}
        {/* <SwiperComponentBig cards={cards} /> */}
      </div>
      <InfoCards cards={content}  />
      
      <br/><br/>
      <br/><br/>
      <br/>
      <br/>
      <div>
      {/* <ToggleSwitch /> */}
      {/* <SwiperComponent cards={cards} /> */}
      </div>
      {/* <div><CoinDrive /></div> */}
      
      <div style={styles.touchableOpacity}>
        <BreathingButton />
      </div>
      {/* <ImageComponent /> */}
      {/* <div style={styles.conteynerimg}>
        <img src={require('../img/centr.png')} alt="telegram" style={styles.img}    />
      </div> */}
      

      

    
    </div>
    
  );
}

const styles = {
  touchableOpacity: {
    margin: '0 auto', // Adjust this value to position above the tab navigation
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    position: 'fixed',
    left: '20px',
    right: '20px',
    bottom: '100px',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
   img: {
    borderRadius:"60%",
    Overlay: 'hidden',


   },
   conteynerimg:{
    padding:"10px",
    display:"flex",
    justifyContent: 'center',
    alignItems:"center",
    margin: "0 100px 0px 100px",padding: '30px'

    

   }
  
  }

export default Main;
